import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { LoginToUseFavourites } from '../../pages/favourites/LoginToUseFavourites';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useFavouritesStore } from '@Stores/StoreContext';

export const LoginToUseFavouritesModal: React.FC = () => {
  const { t } = useTranslation();
  const [loginModal, setShowLoginModal] = useFavouritesStore(
    ({ loginModal, setShowLoginModal }) => [loginModal, setShowLoginModal],
  );

  const onCloseHandler = () => setShowLoginModal(false);

  return (
    <LoadableModal
      show={loginModal.show}
      onClose={onCloseHandler}
      Content={
        <LoginToUseFavourites
          masterId={loginModal.masterId}
          title={t('favourites.loginToUseFavourites.add.title')}
          description={t('favourites.loginToUseFavourites.add.description')}
        />
      }
      mobileFloating
      Header={
        <ModalHeader
          layout="medium"
          onClose={onCloseHandler}
        />
      }
    />
  );
};
